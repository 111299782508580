/* @import "./topnavigation.css"; */
/* @import "./item.css"; */
/* @import "./sidearea.css"; */

@tailwind base;

@tailwind components;

@layer components {
  
  /* .title-text {
    @apply text-xl text-gray-500 tracking-wider font-semibold text-opacity-80 
    mr-auto  ml-4 my-auto 
    transition duration-300 ease-in-out 
    cursor-pointer;

  }

  .search {
    @apply w-2/5 flex items-center justify-start 
    bg-gray-400 dark:bg-gray-600
    text-gray-500
    px-2 h-9 ml-0 mr-0 
    rounded-md shadow-md
    transition duration-300 ease-in-out;
  }

  .search-input {
    @apply w-full font-sans font-semibold
    bg-transparent outline-none 
    text-gray-500  placeholder-gray-500
    pl-1 rounded; 
  } */



  
}



@tailwind utilities;

::-webkit-scrollbar {
  background-color: transparent;

}

::-webkit-scrollbar-thumb {
  scrollbar-width: thin ;
  @apply bg-gray-400 dark:bg-gray-600;
}

.thin-scrollbar {
  /* scrollbar-width: thin ; */
  /* scrollbar-color: rebeccapurple rebeccapurple; */
  /* scrollbar-color: var(--secondary) var(--primary); */
}




/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
